const config = {
  defaultLocale: 'en-au',
  shopifyCookieExpiry: 30,
  context: {
    'en-au': {
      regionSwitcherCountryCode: 'AU',
      shopifyDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_AU,
      shopifyAdminDomain: process.env.SHOPIFY_ADMIN_STORE_DOMAIN_AU,
      shopifyStoreName: 'bauwerkcolourau',
      shopifyAPIToken:
        process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN_AU,
      shopifyMultiPassSecret: process.env.NEXT_SHOPIFY_MULTIPASS_SECRET_AU,
      shopifyAdminApiToken: process.env.SHOPIFY_ADMIN_API_TOKEN_AU,
      shopifyHeadlessShopId:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_SHOP_ID_AU,
      shopifyHeadlessClientId:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_CLIENT_ID_AU,
      shopifyHeadlessClientSecret:
        process.env.SHOPIFY_HEADLESS_CLIENT_SECRET_AU,
      shopifyHeadlessCallbackUri:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_CALLBACK_URI_AU,
      shopifyHeadlessLogoutRedirectUri:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_LOGOUT_REDIRECT_URI_AU,
      shopifyHeadlessIdTokenCookieName: 'bwk_au_idToken',
      defaultCurrencyCountryCode: 'AU',
      defaultCurrencyCode: 'AUD',
      language: 'EN',
      algoliaProductIndex: process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX_AU,
      algoliaCollectionIndex: 'shopify_en_au_collections',
      cartIdCookieName: 'bwk_au_cartId_2022',
      checkoutIdCookieName: 'bwk_au_checkoutId_2022',
      checkoutURLCookieName: 'bwk_au_checkoutUrl_2022',
      customerTokenCookieName: 'bwk_au_customerToken',
      storefrontCustomerTokenCookieName: 'bwk_au_storefrontCustomerToken',
      algoliaPrismicIndex: 'prismic_docs',
      paymentMethods: [
        'MASTERCARD',
        'VISA',
        'APPLEPAY',
        'GOOGLEPAY',
        'SHOPPAY',
        'BANKTRANSFER',
      ],
      calculatorUnit: 'metres',
      openGraphUrlWithLocale: 'https://www.bauwerkcolour.com',
      locale: 'en-au',
      tradeFormRecipient: 'info@bauwerk.com.au',
      sauceId: process.env.NEXT_PUBLIC_SAUCE_ID_AU,
      b2b: true,
    },
    'en-us': {
      regionSwitcherCountryCode: 'US',
      shopifyDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_US,
      shopifyStoreName: 'bauwerkcolourus',
      shopifyAdminDomain: process.env.SHOPIFY_ADMIN_STORE_DOMAIN_US,
      shopifyAPIToken:
        process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN_US,
      shopifyMultiPassSecret: process.env.NEXT_SHOPIFY_MULTIPASS_SECRET_US,
      shopifyAdminApiToken: process.env.SHOPIFY_ADMIN_API_TOKEN_US,
      shopifyHeadlessShopId:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_SHOP_ID_US,
      shopifyHeadlessClientId:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_CLIENT_ID_US,
      shopifyHeadlessClientSecret:
        process.env.SHOPIFY_HEADLESS_CLIENT_SECRET_US,
      shopifyHeadlessCallbackUri:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_CALLBACK_URI_EN_US,
      shopifyHeadlessLogoutRedirectUri:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_LOGOUT_REDIRECT_URI_EN_US,
      shopifyHeadlessIdTokenCookieName: 'bwk_us_idToken',
      defaultCurrencyCountryCode: 'US',
      defaultCurrencyCode: 'USD',
      language: 'EN',
      algoliaProductIndex: process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX_US,
      algoliaCollectionIndex: 'shopify_en_us_collections',
      cartIdCookieName: 'bwk_us_cartId',
      checkoutIdCookieName: 'bwk_us_checkoutId',
      checkoutURLCookieName: 'bwk_us_checkoutUrl',
      customerTokenCookieName: 'bwk_us_customerToken',
      storefrontCustomerTokenCookieName: 'bwk_us_storefrontCustomerToken',
      algoliaPrismicIndex: 'prismic_docs',
      paymentMethods: [
        'MASTERCARD',
        'VISA',
        'APPLEPAY',
        'GOOGLEPAY',
        'SHOPPAY',
        'BANKTRANSFER',
      ],
      calculatorUnit: 'feet',
      openGraphUrlWithLocale: 'https://www.bauwerkcolour.com/en-us',
      locale: 'en-us',
      prismicLocale: 'en-au',
      tradeFormRecipient: 'sales@bauwerkcolour.com',
      sauceId: process.env.NEXT_PUBLIC_SAUCE_ID_AU,
      b2b: true,
    },
    'es-us': {
      regionSwitcherCountryCode: 'ES',
      shopifyDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_US,
      shopifyStoreName: 'bauwerkcolourus',
      shopifyAdminDomain: process.env.SHOPIFY_ADMIN_STORE_DOMAIN_US,
      shopifyAPIToken:
        process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN_US,
      shopifyMultiPassSecret: process.env.NEXT_SHOPIFY_MULTIPASS_SECRET_US,
      shopifyAdminApiToken: process.env.SHOPIFY_ADMIN_API_TOKEN_US,
      shopifyHeadlessShopId:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_SHOP_ID_US,
      shopifyHeadlessClientId:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_CLIENT_ID_US,
      shopifyHeadlessClientSecret:
        process.env.SHOPIFY_HEADLESS_CLIENT_SECRET_US,
      shopifyHeadlessCallbackUri:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_CALLBACK_URI_ES_US,
      shopifyHeadlessLogoutRedirectUri:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_LOGOUT_REDIRECT_URI_ES_US,
      shopifyHeadlessIdTokenCookieName: 'bwk_us_idToken',
      defaultCurrencyCountryCode: 'ES',
      defaultCurrencyCode: 'USD',
      language: 'ES',
      algoliaProductIndex: process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX_US,
      algoliaCollectionIndex: 'shopify_es_us_collections',
      cartIdCookieName: 'bwk_es_us_cartId',
      checkoutIdCookieName: 'bwk_es_us_checkoutId',
      checkoutURLCookieName: 'bwk_es_us_checkoutUrl',
      customerTokenCookieName: 'bwk_us_customerToken',
      storefrontCustomerTokenCookieName: 'bwk_us_storefrontCustomerToken',
      algoliaPrismicIndex: 'prismic_docs',
      paymentMethods: [
        'MASTERCARD',
        'VISA',
        'APPLEPAY',
        'GOOGLEPAY',
        'SHOPPAY',
        'BANKTRANSFER',
      ],
      calculatorUnit: 'feet',
      openGraphUrlWithLocale: 'https://www.bauwerkcolour.com/es-us',
      locale: 'es-us',
      tradeFormRecipient: 'sales@bauwerkcolour.com',
      sauceId: process.env.NEXT_PUBLIC_SAUCE_ID_AU,
      b2b: true,
    },
    'en-gb': {
      regionSwitcherCountryCode: 'GB',
      shopifyStoreName: 'bauwerkcolouruk',
      shopifyDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_UK,
      shopifyAdminDomain: process.env.SHOPIFY_ADMIN_STORE_DOMAIN_UK,
      shopifyAPIToken:
        process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN_UK,
      shopifyMultiPassSecret: process.env.NEXT_SHOPIFY_MULTIPASS_SECRET_UK,
      shopifyAdminApiToken: process.env.SHOPIFY_ADMIN_API_TOKEN_UK,
      shopifyHeadlessShopId:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_SHOP_ID_UK,
      shopifyHeadlessClientId:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_CLIENT_ID_UK,
      shopifyHeadlessClientSecret:
        process.env.SHOPIFY_HEADLESS_CLIENT_SECRET_UK,
      shopifyHeadlessCallbackUri:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_CALLBACK_URI_UK,
      shopifyHeadlessLogoutRedirectUri:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_LOGOUT_REDIRECT_URI_UK,
      shopifyHeadlessIdTokenCookieName: 'bwk_gb_idToken',
      defaultCurrencyCountryCode: 'GB',
      defaultCurrencyCode: 'GBP',
      language: 'EN',
      algoliaProductIndex: process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX_UK,
      algoliaCollectionIndex: 'shopify_en_gb_collections',
      cartIdCookieName: 'bwk_uk_cartId',
      checkoutIdCookieName: 'bwk_uk_checkoutId',
      checkoutURLCookieName: 'bwk_uk_checkoutUrl',
      customerTokenCookieName: 'bwk_uk_customerToken',
      storefrontCustomerTokenCookieName: 'bwk_uk_storefrontCustomerToken',
      algoliaPrismicIndex: 'prismic_docs',
      paymentMethods: [
        'MASTERCARD',
        'VISA',
        'APPLEPAY',
        'GOOGLEPAY',
        'SHOPPAY',
        'BANKTRANSFER',
      ],
      calculatorUnit: 'metres',
      openGraphUrlWithLocale: 'https://www.bauwerkcolour.com/en-gb',
      locale: 'en-gb',
      prismicLocale: 'en-au',
      tradeFormRecipient: 'sales@bauwerkcolour.com',
      sauceId: process.env.NEXT_PUBLIC_SAUCE_ID_AU,
      b2b: false,
    },
    'en-eu': {
      regionSwitcherCountryCode: 'EU',
      shopifyDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_EU,
      shopifyAdminDomain: process.env.SHOPIFY_ADMIN_STORE_DOMAIN_EU,
      shopifyStoreName: 'bauwerkcoloureu',
      shopifyAPIToken:
        process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN_EU,
      shopifyMultiPassSecret: process.env.NEXT_SHOPIFY_MULTIPASS_SECRET_EU,
      shopifyAdminApiToken: process.env.SHOPIFY_ADMIN_API_TOKEN_EU,
      shopifyHeadlessShopId:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_SHOP_ID_EU,
      shopifyHeadlessClientId:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_CLIENT_ID_EU,
      shopifyHeadlessClientSecret:
        process.env.SHOPIFY_HEADLESS_CLIENT_SECRET_EU,
      shopifyHeadlessCallbackUri:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_CALLBACK_URI_EN_EU,
      shopifyHeadlessLogoutRedirectUri:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_LOGOUT_REDIRECT_URI_EN_EU,
      shopifyHeadlessIdTokenCookieName: 'bwk_eu_idToken',
      defaultCurrencyCountryCode: 'DE',
      defaultCurrencyCode: 'EUR',
      language: 'EN',
      algoliaProductIndex: process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX_EU,
      algoliaCollectionIndex: 'shopify_en_eu_collections',
      cartIdCookieName: 'bwk_eu_cartId',
      checkoutIdCookieName: 'bwk_eu_checkoutId',
      checkoutURLCookieName: 'bwk_eu_checkoutUrl',
      customerTokenCookieName: 'bwk_eu_customerToken',
      storefrontCustomerTokenCookieName: 'bwk_eu_storefrontCustomerToken',
      algoliaPrismicIndex: 'prismic_docs',
      paymentMethods: [
        'MASTERCARD',
        'VISA',
        'AMEX',
        'MAESTRO',
        'BANCONTACT',
        'IDEAL',
        'SOFORT',
        'SHOPPAY',
        'APPLEPAY',
        'GOOGLEPAY',
        'KLARNA',
      ],
      calculatorUnit: 'metres',
      taxAmount: 0.19,
      openGraphUrlWithLocale: 'https://www.bauwerkcolour.com/en-eu',
      locale: 'en-eu',
      prismicLocale: 'en-au',
      tradeFormRecipient: 'sales@bauwerkcolour.com',
      sauceId: process.env.NEXT_PUBLIC_SAUCE_ID_AU,
      b2b: false,
    },
    'de-eu': {
      regionSwitcherCountryCode: 'DE',
      shopifyDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_EU,
      shopifyAdminDomain: process.env.SHOPIFY_ADMIN_STORE_DOMAIN_EU,
      shopifyStoreName: 'bauwerkcoloureu',
      shopifyAPIToken:
        process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN_EU,
      shopifyMultiPassSecret: process.env.NEXT_SHOPIFY_MULTIPASS_SECRET_EU,
      shopifyAdminApiToken: process.env.SHOPIFY_ADMIN_API_TOKEN_EU,
      shopifyHeadlessShopId:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_SHOP_ID_EU,
      shopifyHeadlessClientId:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_CLIENT_ID_EU,
      shopifyHeadlessClientSecret:
        process.env.SHOPIFY_HEADLESS_CLIENT_SECRET_EU,
      shopifyHeadlessCallbackUri:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_CALLBACK_URI_DE_EU,
      shopifyHeadlessLogoutRedirectUri:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_LOGOUT_REDIRECT_URI_DE_EU,
      shopifyHeadlessIdTokenCookieName: 'bwk_eu_idToken',
      defaultCurrencyCountryCode: 'DE',
      defaultCurrencyCode: 'EUR',
      language: 'DE',
      algoliaProductIndex: process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX_EU,
      algoliaCollectionIndex: 'shopify_en_eu_collections',
      cartIdCookieName: 'bwk_de_eu_cartId',
      checkoutIdCookieName: 'bwk_de_eu_checkoutId',
      checkoutURLCookieName: 'bwk_de_eu_checkoutUrl',
      customerTokenCookieName: 'bwk_eu_customerToken',
      storefrontCustomerTokenCookieName: 'bwk_eu_storefrontCustomerToken',
      algoliaPrismicIndex: 'prismic_docs',
      paymentMethods: [
        'MASTERCARD',
        'VISA',
        'AMEX',
        'MAESTRO',
        'BANCONTACT',
        'IDEAL',
        'SOFORT',
        'SHOPPAY',
        'APPLEPAY',
        'GOOGLEPAY',
        'KLARNA',
      ],
      calculatorUnit: 'metres',
      taxAmount: 0.19,
      openGraphUrlWithLocale: 'https://www.bauwerkcolour.com/de-eu',
      locale: 'de-eu',
      tradeFormRecipient: 'sales@bauwerkcolour.com',
      sauceId: process.env.NEXT_PUBLIC_SAUCE_ID_AU,
      b2b: false,
    },
    'es-eu': {
      regionSwitcherCountryCode: 'ES',
      shopifyDomain: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN_EU,
      shopifyAdminDomain: process.env.SHOPIFY_ADMIN_STORE_DOMAIN_EU,
      shopifyStoreName: 'bauwerkcoloureu',
      shopifyAPIToken:
        process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN_EU,
      shopifyMultiPassSecret: process.env.NEXT_SHOPIFY_MULTIPASS_SECRET_EU,
      shopifyAdminApiToken: process.env.SHOPIFY_ADMIN_API_TOKEN_EU,
      shopifyHeadlessShopId:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_SHOP_ID_EU,
      shopifyHeadlessClientId:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_CLIENT_ID_EU,
      shopifyHeadlessClientSecret:
        process.env.SHOPIFY_HEADLESS_CLIENT_SECRET_EU,
      shopifyHeadlessCallbackUri:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_CALLBACK_URI_ES_EU,
      shopifyHeadlessLogoutRedirectUri:
        process.env.NEXT_PUBLIC_SHOPIFY_HEADLESS_LOGOUT_REDIRECT_URI_ES_EU,
      shopifyHeadlessIdTokenCookieName: 'bwk_eu_idToken',
      defaultCurrencyCountryCode: 'DE', // we want de for all eu locales
      defaultCurrencyCode: 'EUR',
      language: 'ES',
      algoliaProductIndex: process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX_EU,
      algoliaCollectionIndex: 'shopify_en_eu_collections',
      cartIdCookieName: 'bwk_es_eu_cartId',
      checkoutIdCookieName: 'bwk_es_eu_checkoutId',
      checkoutURLCookieName: 'bwk_es_eu_checkoutUrl',
      customerTokenCookieName: 'bwk_eu_customerToken',
      storefrontCustomerTokenCookieName: 'bwk_eu_storefrontCustomerToken',
      algoliaPrismicIndex: 'prismic_docs',
      paymentMethods: [
        'MASTERCARD',
        'VISA',
        'AMEX',
        'MAESTRO',
        'BANCONTACT',
        'IDEAL',
        'SOFORT',
        'SHOPPAY',
        'APPLEPAY',
        'GOOGLEPAY',
        'KLARNA',
      ],
      calculatorUnit: 'metres',
      taxAmount: 0.19,
      openGraphUrlWithLocale: 'https://www.bauwerkcolour.com/es-eu',
      locale: 'es-eu',
      tradeFormRecipient: 'sales@bauwerkcolour.com',
      sauceId: process.env.NEXT_PUBLIC_SAUCE_ID_AU,
      b2b: false,
    },
  },
}

module.exports = { config }
